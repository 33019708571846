<template>
  <div class="ty-space-container clearfix">
    <van-tabs v-model="active" color="#01c1aa">
      <van-tab title="我的渔菜">
          <div class="item-list">
              <div class="item" @click="toDetails(item.farmId)" v-for="(item) in farmList" :key="item.farmId">
                  <div class="img-container">
                    <img :src="item.farmImg" alt="">
                  </div>
                  <div class="item-info">
                      <div class="gap-mb-xs">
                        <span class="title">{{item.farmName}}</span>
                        <span class="item-tag" v-if="item.tag">{{ item.tag }}</span>
                      </div>
                      <div class="sub-title only-2">{{item.subTitle}}</div>
                  </div>
                  <div class="select-space">
                    <div>
                      <span>价格：<span class="price">&yen;{{item.minSalePrice}}</span>元起</span>
                    </div>
                    <div>
                      <van-button round color="#8fc42e" size="mini">选择土地</van-button>
                    </div>
                  </div>
              </div>
          </div>
      </van-tab>
      <van-tab title="桃源别墅">
        <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            :style="{height: (windowHeight-94) + 'px', overflow:'auto'}">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <div class="hotel-list">
              <van-row type="flex" gutter="12" :column-num="2">
                <van-col class="gap-mb" v-for="(item, index) of hotelList" :key="index" :span="12" @click="onHotelDetail">
                  <van-image :src="item.img" height="100" />
                  <div class="hotel-info">
                    <div class="hotel-name">{{item.title}}</div>
                    <div class="flex-row flex-between">
                      <div class="hotel-price"><span class="price">&yen;{{item.price}}</span>起</div>
                      <div class="hotel-params">
                        <img src="../../assets/images/ico12-room.png">
                        <span>{{item.rooms}}</span>
                        <img src="../../assets/images/ico12-bed.png">
                        <span>{{item.beds}}</span>
                        <img src="../../assets/images/ico12-man.png">
                        <span>{{item.men}}</span>
                      </div>
                    </div>
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-list>
        </van-pull-refresh>

      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
let timer = 0

export default {
  name: "TySpace",
  data(){
    return{
      active:0,
      loading: false,
      finished: true,
      refreshing: false,
      imgArr: [
        require('../../assets/h01.jpg'),
        require('../../assets/h02.jpg'),
        require('../../assets/h03.jpg')
      ],
      pageNum: 1,
      pageSize: 12,
      pageMax: 4,
      farmList:[],
      hotelList: []
    }
  },
  created() {
    this.getFarmList();
  },
  computed: {
    windowHeight() {
      return this.$store.state.windowHeight
    }
  },
  methods:{
    toDetails(farmId){
      this.$router.push({path:'/ycty/tySpaceDetails',query:{farmId:farmId}})
    },
    getList() {
      setTimeout(() => {
        let arr = [], imgIndex = 0
        for(let i=1; i <= this.pageSize; i++) {
          arr.push({
            title: '私人别墅',
            price: 230.01,
            rooms: 5,
            beds: 7,
            men: 10,
            img: this.imgArr[imgIndex]
          })
          imgIndex++
          if(imgIndex > 2) {
            imgIndex = 0
          }
        }

        //this.hotelList = this.pageNum === 1 ? arr : this.hotelList.concat(arr)

        if(this.pageNum >= this.pageMax) {
          this.finished = true
        }

        this.refreshing = false
        this.loading = false
      }, 1000)
    },
    onLoad() {
      this.pageNum++
      this.getList()
    },
    onRefresh() {
      this.finished = false
      this.pageNum = 1
      this.getList()
    },
    onHotelDetail() {
      this.$router.push('/ycty/hotelDetail')
    },
    getFarmList(){
      this.$axios.get("/api/wxmp/farm/list",{
        params:{
          branchId:this.$store.getters.getLocation.branchId
        }
      }).then((res) =>{
        //console.log(JSON.stringify(res))
        this.farmList = res.data;
      }).catch((err) =>{
        console.log(JSON.stringify(err));
      })
    }
  },
  mounted() {
    this.onRefresh()
  },

}
</script>

<style lang="scss" scoped>
  .ty-space-container {
    min-height: calc(100% - 50px);
    background-color: #f5f5f5;
  }
  .item{
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 10px;
    margin: 12px;
  }
  .item img{
    width: 100%;
    max-height: 180px;
  }
  .item-info{
    text-align: left;
    padding: 10px;
  }
  .item-info .title{
    color: black;
    font-size: 14px;
    padding-right: 5px;
    font-weight: bolder;
  }

  .item-info .sub-title{
    color: #666;
    font-size: 12px;
    overflow: hidden;
  }
  .select-space{
    color: black;
    text-align: left;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price{
    color: red;
    padding-right:5px;
  }
  .hotel-list {
    padding: 12px;
  }
  .hotel-info{
  }

  .hotel-name{
    line-height: 1.5;
    height: 3em;
    overflow: hidden;
  }
  .hotel-params img {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    margin-left: 5px;
  }
  .hotel-params span {
    vertical-align: middle;
  }
</style>
