<template>
<div class="ui-page is-light">
    <div class="my-person">
        <div class="my-person__avatar">
            <van-image
                class="display-block"
                width="60px"
                height="60px"
                fit="cover"
                round
                :src="infoJson.avatarUrl"
            />
        </div>
        <div class="flex-item">
            <div class="my-person__name">{{infoJson.nickName}}</div>
            <div class="my-person__mobile">{{infoJson.mobile}}</div>
        </div>
        <div>
            <van-icon name="setting-o" size="30" />
        </div>
    </div>

    <div class="my-assets">
        <div class="my-assets__item">
            <div class="my-assets__value">0.00</div>
            <div class="my-assets__label">余额</div>
        </div>
        <div class="my-assets__item">
            <div class="my-assets__value">0</div>
            <div class="my-assets__label">信用分</div>
        </div>
        <div class="my-assets__item" @click="toMember">
            <div class="my-assets__value">{{memberLevelNameArray[infoJson.memberLevel]}}</div>
            <div class="my-assets__label">等级</div>
        </div>
    </div>

    <van-panel class="gap-mb">
        <template #header>
            <div class="my-card-head">
                <div class="my-card-head__caption">商城订单</div>
            </div>
        </template>
        <div class="my-orders">
            <div class="my-orders__item" @click="toShopOrderList(-1)">
                <div class="my-orders__icon"><van-icon name="orders-o" /></div>
                <div class="my-orders__label">全部订单</div>
                <span class="my-orders__order-count" v-if="infoJson.shopOrderTotalNum">{{infoJson.shopOrderTotalNum}}</span>
            </div>
            <div class="my-orders__item" @click="toShopOrderList(0)">
                <div class="my-orders__icon"><van-icon name="balance-list-o" /></div>
                <div class="my-orders__label">待付款</div>
                <span class="my-orders__order-count" v-if="infoJson.shopOrderWaitPayNum">{{infoJson.shopOrderWaitPayNum}}</span>
            </div>
            <div class="my-orders__item" @click="toShopOrderList(1)">
                <div class="my-orders__icon"><van-icon name="todo-list-o" /></div>
                <div class="my-orders__label">待发货</div>
              <span class="my-orders__order-count" v-if="infoJson.shopOrderHasPayNum">{{infoJson.shopOrderHasPayNum}}</span>
            </div>
            <div class="my-orders__item" @click="toShopOrderList(3)">
                <div class="my-orders__icon"><van-icon name="logistics" /></div>
                <div class="my-orders__label">待收货</div>
            </div>
        </div>
    </van-panel>
    <van-panel class="gap-mb">
      <template #header>
        <div class="my-card-head">
          <div class="my-card-head__caption">渔菜订单</div>
        </div>
      </template>
      <div class="my-orders">
        <div class="my-orders__item" @click="toSpaceOrderList(-1)">
          <div class="my-orders__icon"><van-icon name="orders-o" /></div>
          <div class="my-orders__label">全部订单</div>
          <span class="my-orders__order-count" v-if="infoJson.ycOrderTotalNum">{{infoJson.ycOrderTotalNum}}</span>
        </div>
        <div class="my-orders__item" @click="toSpaceOrderList(0)">
          <div class="my-orders__icon"><van-icon name="balance-list-o" /></div>
          <div class="my-orders__label">待付款</div>
          <span class="my-orders__order-count" v-if="infoJson.ycOrderWaitPayNum">{{infoJson.ycOrderWaitPayNum}}</span>
        </div>
        <div class="my-orders__item" @click="toSpaceOrderList(1)">
          <div class="my-orders__icon"><van-icon name="todo-list-o" /></div>
          <div class="my-orders__label">已付款</div>
          <span class="my-orders__order-count" v-if="infoJson.ycOrderHasPayNum">{{infoJson.ycOrderHasPayNum}}</span>
        </div>
      </div>
    </van-panel>

<!--    <van-panel class="gap-mb">-->
<!--        <template #header>-->
<!--            <div class="my-card-head flex-between">-->
<!--                <div class="my-card-head__caption">我的收藏</div>-->
<!--                <div class="my-card-head__more">-->
<!--                    <span class="vertical-middle">全部</span>-->
<!--                    <van-icon class="vertical-middle" name="arrow" />-->
<!--                </div>-->
<!--            </div>-->
<!--        </template>-->
<!--        <div class="my-collection">-->
<!--            <div class="my-collection__item">-->
<!--                <div class="my-collection__icon">0</div>-->
<!--                <div class="my-collection__label">快店</div>-->
<!--            </div>-->
<!--            <div class="my-collection__item">-->
<!--                <div class="my-collection__icon">0</div>-->
<!--                <div class="my-collection__label">团购</div>-->
<!--            </div>-->
<!--            <div class="my-collection__item">-->
<!--                <div class="my-collection__icon">0</div>-->
<!--                <div class="my-collection__label">餐饮</div>-->
<!--            </div>-->
<!--            <div class="my-collection__item">-->
<!--                <div class="my-collection__icon">0</div>-->
<!--                <div class="my-collection__label">预约</div>-->
<!--            </div>-->
<!--            <div class="my-collection__item">-->
<!--                <div class="my-collection__icon">0</div>-->
<!--                <div class="my-collection__label">商城</div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </van-panel>-->

    <div>
        <van-cell title="我的推广" icon="send-gift-o" is-link />
        <van-cell title="商家中心" icon="send-gift-o" is-link />
        <van-cell title="在线客服" icon="send-gift-o" is-link />
        <van-cell title="我的发布" icon="send-gift-o" is-link />
    </div>

</div>
</template>

<script>
export default {
  /**
   * "infoJson":{"openid":"o9tqj5imgpgKySvrSQbocmBVmyTo","nickName":"骆锦强",
   * "avatarUrl":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKEd3UTnQHI8aCYaFowrowqfy02xXoicUx2S0AG43UhB5jW62L9mvUFpCAlq1tZsnfQB8xFgF0ib62w/132",
   * "mobile":"","shopOrderTotalNum":0,"shopOrderWaitPayNum":0,"shopOrderHasPayNum":0,
   * "ycOrderTotalNum":1,"ycOrderWaitPayNum":1,"ycOrderHasPayNum":0},"code":0}
   */
    name: "My",
    data(){
      return{
        infoJson:{
          openid:'',
          nickName:'',
          avatarUrl:'',
          mobile:'',
          memberLevel:0,
          shopOrderTotalNum:0,
          shopOrderWaitPayNum:0,
          shopOrderHasPayNum:0,
          ycOrderTotalNum:0,
          ycOrderWaitPayNum:0,
          ycOrderHasPayNum:0
        },
        memberLevelNameArray:[
          '普通会员',
          '会员VIP',
          '核心会员VIP'
        ]
      }
    },
  created() {
    this.init();
  },
  methods:{
      init(){
        this.$axios.get('/api/wxmp/my/index',{
          params:{
            openid:this.$store.state.openid
          }
        }).then(res =>{
            if(res.code === 0){
              //console.log('res =>',JSON.stringify(res));
              this.infoJson = res.infoJson;
            }else{
              this.$toast.fail(res.msg)
            }
        }).catch(err =>{

        })
      },
      toShopOrderList(orderStatus){
          this.$router.push({path:'/orderList',query:{orderStatus:orderStatus}})
      },
      toSpaceOrderList(orderStatus){
        this.$router.push({path:'/ycty/tySpaceOrderList',query:{orderStatus:orderStatus}})
      },
      toMember(){
        this.$router.push({path:'/member'});
      }
  }
}
</script>

<style lang="scss" scoped>
.my-person {
    background-color: $--color-primary;
    padding: 20px 12px 60px;
    color: #fff;
    display: flex;
    align-items: center;

    &__avatar {
        border-radius: 50%;
        border: 2px solid #fff;
        line-height: 1;
        margin-right: 10px;
    }

    &__name {
        font-size: 20px;
        margin-bottom: 5px;
    }
}

.my-assets {
    display: flex;
    position: relative;
    margin: -40px 12px 12px;
    background-color: #fff;
    border-radius: 8px;
    height: 80px;
    align-items: center;
    text-align: center;

    &__item {
        flex: 1;
        min-width: 0;
    }

    &__label {
        font-size: 12px;
        color: #787878;
    }

    &__value {
        font-size: 18px;
        margin-bottom: 5px;
    }
}

.my-card-head {
    margin: 0 12px;
    border-bottom: 1px solid #eee;
    height: 44px;
    display: flex;
    align-items: center;

    &__caption {
        font-size: 16px;
        font-weight: bold;
    }

    &__more {
        color: #999;
    }
}
.my-orders {
    display: flex;
    padding: 12px 0;

    &__item {
        width: 25%;
        min-width: 0;
        text-align: center;
        position: relative;
    }

    &__icon {
        font-size: 24px;
        color: rgb(211, 77, 21);
    }

    &__label {
        font-size: 12px;
        color: #787878;
    }

    &__order-count{
      position: absolute;
      top: -5px;
      right: 25%;
      background-color: $_-color-primary;
      display: inline-block;
      width: 15px;
      height: 15px;
      line-height: 15px;
      border-radius: 20px;
      text-align: center;
      color: #fff;
    }
}
.my-collection {
    display: flex;
    padding: 12px 0;

    &__item {
        flex: 1;
        min-width: 0;
        text-align: center;
    }

    &__icon {
        font-size: 18px;
        color: rgb(63, 156, 255);
        margin-bottom: 6px;
    }

    &__label {
        font-size: 12px;
        color: #787878;
    }
}
</style>
