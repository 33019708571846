<template>
  <div class="member-wrapper">
    <div class="member-header">
      <div class="user-info">
        <van-image
            class="display-block"
            width="60px"
            height="60px"
            fit="cover"
            round
            :src="infoJson.avatarUrl"
        />
        <div class="user-name-wrapper">
          <p class="user-name">{{infoJson.nickName}}</p>
          <p class="user-level">{{memberLevelNameArray[infoJson.memberLevel]}}</p>
        </div>
      </div>
    </div>
    <div class="member-list-wrapper">
      <div class="member-card-item">
        <van-icon name="gem-o" size="28" color="red" />
        <p class="card-name">会员VIP</p>
        <p class="card-remark">享受VIP服务和折扣价</p>
        <p class="price">&yen;19.9</p>
        <div class="member-bottom " @click="gotoUpgrade(1,'19.9')" v-if="infoJson.memberLevel <= 0">我要升级</div>
        <div class="member-bottom disabled" v-else>我要升级</div>
      </div>
      <div class="member-card-item">
        <van-icon name="vip-card-o" size="28" color="red" />
        <p class="card-name">核心会员VIP</p>
        <p class="card-remark">享受更高级别VIP服务</p>
        <p class="price">&yen;199</p>
        <div class="member-bottom " @click="gotoUpgrade(2,'199')" v-if="infoJson.memberLevel <= 1">我要升级</div>
        <div class="member-bottom disabled" v-else>我要升级</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Member",
  data(){
    return{
      infoJson:{
        openid:'',
        nickName:'',
        avatarUrl:'',
        memberLevel:0,
        mobile:''
      },
      memberLevelNameArray:[
          '普通会员',
          '会员VIP',
          '核心会员VIP'
      ]
    }
  },
  created() {
    this.init();
  },
  methods:{
    gotoUpgrade(memberLevel,money){
      this.$axios.postForm('/api/wxmp/my/memberUpgrade',{
        openid:this.infoJson.openid,
        memberLevel:memberLevel,
        money:'0.01'
      }).then(res =>{
        if(res.code === 0){
          let that = this;
          window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": res.data.appId,
                //公众号名称，由商户传入
                "timeStamp": res.data.timeStamp,
                //时间戳，自1970年以来的秒数
                "nonceStr": res.data.nonceStr,
                //随机串
                "package": res.data.package,
                "signType": res.data.signType,
                //微信签名方式：
                "paySign": res.data.paySign //微信签名
              },
              function(res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  that.$toast.success('支付成功');
                  setTimeout(() =>{
                    that.$router.push("/my")
                  },1000)
                }else{
                  that.$toast.fail('支付失败');
                }
              });
        }else{
          this.$toast.fail(res.msg)
        }
      }).catch(err =>{
        console.log('err',JSON.stringify(err));
      })
    },
    init(){
      this.$axios.get('/api/wxmp/my/info',{
        params:{
          openid:this.$store.state.openid
        }
      }).then(res =>{
        if(res.code === 0){
          //console.log('res =>',JSON.stringify(res));
          this.infoJson = res.infoJson;
        }else{
          this.$toast.fail(res.msg)
        }
      }).catch(err =>{

      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .member-wrapper{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    overflow: hidden;
  }
  .member-header{
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
  }
  .user-info{
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: $__color-primary;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
  }
  .user-name-wrapper{
    color: #fff;
  }
  .user-name-wrapper{
    padding-left: 20px;
  }
  .user-name-wrapper p{
    margin: 0;
    padding: 5px 0;
  }
  .user-level{
    color: #333;
    font-size: 12px;
  }
  .member-list-wrapper{
    margin-top: 5px;
    text-align: center;
    display: flex;
    background-color: #fff;
  }
  .member-list-wrapper p{
    margin: 0;
    padding: 5px 0;
  }
  .card-name{
    color: black;
    font-size: 16px;
    font-weight: bolder;
  }
  .price{
    color: red;
  }
  .member-card-item{
    border: 1px solid #f5f5f5;
    padding: 10px;
    flex: 1;
    box-sizing: border-box;
  }
  .member-bottom{
    width: 80px;
    height: 35px;
    line-height: 35px;
    margin: 0 auto;
    background-color: $--color-primary;
    color: #fff;
    border-radius: 5px;
  }
  .disabled{
    background-color: #888;
  }
</style>
