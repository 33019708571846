<template>
  <div>

  </div>
</template>

<script>

export default {
  name: "TyHotel",
  data(){
    return{

    }
  },
  watch:{
    openid(){
      if(this.openid){
        console.log('openid',this.openid);
        this.toH5();
      }
    }
  },
  computed:{
    openid(){
      return this.$store.state.openid;
    }
  },
  created() {
    if(this.openid){
      this.toH5();
    }
  },
  methods:{
    toH5(){
      window.location.href = 'https://dm-h5.yiyang580.com/#/pages/club/index?ticket='+this.openid+'&appid='+this.$store.state.appId+"&from=wxmp&channelId=52";
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
